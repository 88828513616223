import { put, call, takeEvery } from 'redux-saga/effects'
import { coursesActions } from './courseSlice'
import { loadCourses } from '../../services/Courses'
import { PayloadAction } from '@reduxjs/toolkit'

interface CourseLocale {
  courseLocaleId: string
  title: string
  lang: string
  description: string
  imageUri: string
  datePresent: string
  activityStatus: string
  courseType: string
  courseId: string
}

interface CourseResponse {
  courseId: string
  imageUri: string
  durationHours: number
  difficulty: string
  courseLocales: CourseLocale[]
}

export function* loadCoursesSaga(action: PayloadAction<string>): unknown {
  try {
    const courses = yield call(loadCourses, action.payload)
    yield put(
      coursesActions.loadCoursesSuccess(
        courses.data.map((course: CourseResponse) => ({
          id: course.courseId,
          title: course.courseLocales[0].title,
          description: course.courseLocales[0].description,
          image: course.courseLocales[0].imageUri,
        })),
      ),
    )
  } catch (error: unknown) {
    yield put(coursesActions.loadCoursesError(error as string))
  }
}

export function* watchLoadCoursesSaga() {
  yield takeEvery(coursesActions.loadCourses, loadCoursesSaga)
}
