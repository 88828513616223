import axios from 'axios'

function loadCourses(userId: string) {
  return axios({
    method: 'get',
    url: 'api/courses/search?prefLang=ru',
    headers: { authorization: `Bearer ${userId}` },
  })
}

export { loadCourses }
