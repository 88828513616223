import './style.scss'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { coursesActions, Course } from '../../../redux/courses/courseSlice'
import { ReactComponent as SaveCourseIcon } from '../../../assets/icons/course-card-icon-default.svg'

export default function CourseList() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const list = useAppSelector(state => state.courses.list)
  const userId: string | null = localStorage.getItem('userId')

  useEffect(() => {
    dispatch(coursesActions.loadCourses({ userId }))
  }, [])

  return (
    <div className="courses">
      <div className="container">
        <h3 className="courses-title">{t('courses-title')}</h3>

        <div className="courses-list">
          {
            list.length
              ? list.map((course: Course) => (
                <div className="course-card" key={course.id}>
                  <button
                    className={`course-card__btn ${course.isSaved ? 'course-card__btn_saved' : ''}`}
                    onClick={() => {}}
                  >
                    <SaveCourseIcon />
                  </button>
                  <img
                    src={course.image || '/assets/images/course-card-img.svg'}
                    className="course-card__img"
                    alt="Course image"
                  >
                  </img>
                  <Link to="" className="course-card__title">
                    {course.title}
                  </Link>
                  <div>{course.description}</div>
                </div>
              ))
              : ''
          }
        </div>
      </div>
    </div>
  )
}
