import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Course {
  id: string
  title: string
  description: string
  isSaved?: boolean
  image?: string
}

interface CoursesState {
  list: Course[]
  isLoading: boolean
  errors: string | string[]
}

const initialState: CoursesState = {
  list: [],
  isLoading: false,
  errors: '',
}

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    loadCourses: (state, _): void => {
      state.isLoading = true
    },
    loadCoursesSuccess: (state, action: PayloadAction<Course[]>): void => {
      state.isLoading = false
      state.list = action.payload
    },
    loadCoursesError: (state, action: PayloadAction<string | string[]>): void => {
      state.isLoading = false
      state.errors = action.payload
    },
  },
})

export const coursesActions = coursesSlice.actions
export default coursesSlice.reducer
