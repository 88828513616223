import axios from 'axios'

function signIn(token: string) {
  return axios({
    method: 'post',
    url: 'api/auth/google',
    headers: { authorization: `Bearer ${token}` },
  })
}

export { signIn }
