import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { Provider } from 'react-redux'
import './i18n'
import App from './App'
import ErrorPage from './components/ErrorPage'
import Auth from './components/Auth'
import store from './redux/store'
import CourseList from './components/Courses/CourseList'
import { createTheme, ThemeProvider } from '@mui/material'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <CourseList />,
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    path: '/auth',
    element: <Auth />,
  },
])

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
